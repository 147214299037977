import React, {useEffect, useState} from 'react';
import {Card, Space, Image, Avatar, DatePicker, Modal, Input, Checkbox, Table, Select} from 'antd';
import './index.css';
import {Col, Container, Row, UncontrolledPopover, PopoverHeader, PopoverBody, FormGroup, Label} from 'reactstrap';
import "flatpickr/dist/themes/material_green.css";
import axios from "axios";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import {
    getChargePointBasedOnTextSearchBody,
    getChargePointBasedOnTextSearchResponse,
    transaction,
    transactionResponse
} from "../../../types/admin";
import {PopNotification} from "../../../shared/notification";
import moment from "moment";
import { CSVLink } from "react-csv";
import {milliToHHMMSS} from "../../../utils/dateUtils";
import {UserTransactionActions} from "./UserTransactionActions";

const UserTransaction: React.FC = ({children}) => {

    const [csvData, setCsvData] = useState<any[]>([{}]);
    const [loading, setLoading] = useState(false);
    const [transactionDataSource, setTransactionDataSource] = useState<transaction[]>();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true, //this line allows the dropdown to show to choose the number of items per page
        pageSizeOptions: ['10', '20', '50', '100', '200', '500', '1000'] //set the options of items per page selecting (pagination dropdown)
    });
    const [chargePointList, setChargePointList] = useState<getChargePointBasedOnTextSearchBody[]>();
    const [selectedChargePoint, setSelectedChargePoint] = useState("all");
    const {RangePicker} = DatePicker;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {token} = useCredentialStore(({token}) => ({
        token
    }));

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: 'Charger Unique Id',
            dataIndex: 'chargerUniqueId',
            key: 'chargerUniqueId',
            render: (text: string) => <span>{text ?? "-"}</span>
        },
        {
            title: 'Started at',
            dataIndex: 'startedAt',
            key: 'startedAt',
            render: (text: string) => <div>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
        },
        {
            title: 'Stopped at',
            dataIndex: 'stoppedAt',
            key: 'stoppedAt',
            render: (text: string) => <div>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text: string) => <div>{text ? milliToHHMMSS(text) : ''}</div>
        },
        {
            title: 'Energy charged (kWh)',
            dataIndex: 'energyCharged',
            key: 'energyCharged',
            render: (text: number) => <div>{text?.toFixed(2)}</div>
        },
        {
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transactionId',
            key: 'transactionId',
        },
        {
            title: 'Earnings (RM)',
            dataIndex: 'earnings',
            key: 'earnings',
            render: (text: number) => <div>{text?.toFixed(2)}</div>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Error',
            dataIndex: 'errorReason',
            key: 'errorReason'
        },
        {
            title: 'Actions',
            dataIndex: 'status',
            key: 'actions',
            render: (text: string, record: any) => {
                return <div><UserTransactionActions status={text} paymentId={record.transactionId}/></div>
            }
        }
    ];

    const onGetTransaction = (page: number, pageSize: number) => {
        setLoading(true);
        axios.get(`/api/v2/transaction?chargePointId=${selectedChargePoint}&page=${page - 1}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            const response: transactionResponse = res.data;
            setTransactionDataSource(response.data);
            setPagination({
                ...pagination,
                current: page,
                pageSize: pageSize,
                total: response.totalElements
            })
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        }).finally(() => {
            setLoading(false);
            setIsModalVisible(false);
        })
    }

    const onGetChargePointList = () => {
        axios.get("/api/chargePointsLocationSocketStatusSummary?page=0&pageSize=1000", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            const response: getChargePointBasedOnTextSearchResponse = res.data;
            const list = [
                {
                    createdTime: 1,
                    id: {id: 'all'},
                    imageId: {id: null},
                    isOperative: true,
                    isPublic: true,
                    method: 'rate',
                    name: 'All',
                    privilegeRate: 0,
                    rate: 0,
                    tariff: 0
                },
                ...response.data
            ]
            setChargePointList(list);
            setSelectedChargePoint(list[0].id.id);
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        })
    }

    const onSearch = () => {
        onGetTransaction(pagination.current, pagination.pageSize)
    }

    const onGetCsvData = (page: number, pageSize: number) => {
        axios.get(`/api/v2/transaction/csv?chargePointId=${selectedChargePoint}&page=${page-1}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            setCsvData(res.data)
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        })
    }

    useEffect(() => {
        onGetTransaction(pagination.current, pagination.pageSize)
        onGetCsvData(pagination.current, pagination.pageSize)
        onGetChargePointList()
    }, []);

    useEffect(() => {
        onSearch()
        onGetTransaction(pagination.current, pagination.pageSize)
        onGetCsvData(pagination.current, pagination.pageSize)
    }, [selectedChargePoint]);

    function handleTableChange(pagination: any) {
        onGetTransaction(pagination.current, pagination.pageSize)
        onGetCsvData(pagination.current, pagination.pageSize)
    }

    return (
        <div>
            <Container fluid={true} style={{marginTop: 20}}>
                <Row style={{marginBottom: 20}}>
                    <Col md={8}>
                        <span className='page-title' style={{marginBottom: 0}}>Charge Point | Transactions</span>
                    </Col>
                    <Col md={4} style={{textAlign: "right"}}>
                        <span className='d-flex' style={{justifyContent: "flex-end", alignItems: "center"}}>
                            <CSVLink data={csvData}>
                                <span
                                    style={{
                                        backgroundColor: "#39B64B",
                                        marginTop: 2,
                                        padding: "5px 15px",
                                        cursor: "pointer",
                                        color: "white",
                                        borderRadius: 7,
                                        display: "inline-block",
                                        textTransform: "uppercase",
                                        marginRight: 10
                                    }}>
                                Export As csv
                            </span>
                            </CSVLink>
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col md={24}>
                        <Select style={{width: "100%"}} value={selectedChargePoint}
                                onChange={(value) => {
                                    setSelectedChargePoint(value)
                                }}>
                            {
                                chargePointList?.map((chargePoint, index) => {
                                        return (
                                            <Select.Option value={chargePoint.id.id}>{chargePoint.name}</Select.Option>
                                        )
                                    }
                                )
                            }
                        </Select>
                    </Col>
                </Row>
                <Row md={12}>
                    <Col md={12} className='card-datavisulization'
                         style={{textAlign: "left", padding: 20, height: "80vh"}}>
                        <Table
                            columns={columns}
                            dataSource={transactionDataSource}
                            loading={loading}
                            pagination={pagination}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default UserTransaction
